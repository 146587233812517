import * as React from "react"
import { Link, graphql } from "gatsby"
import startCase from 'lodash/startCase'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Layout from "../components/layout"
import Seo from "../components/seo"

const Breadcrumbs = ({ location }) => {

  const breadcrumbs = location.pathname.split('/')
    .filter((part) => part !== '')
    .filter((part) => !!part)
    .reduce((result, item) => {
      result.breadCrumbItems.push({
        name: startCase(item),
        url: ['', ...result.allPrevious, item].join('/')
      })

      result.allPrevious.push(item)
      return result
    }, {
      allPrevious: [],
      breadCrumbItems: [{
        name: startCase('Home'),
        url: '/'
      }]
    })

  console.log(breadcrumbs)
    
  return (
    <Breadcrumb>
      {breadcrumbs.breadCrumbItems.map((breadCrumbItem, key) => (
        <Breadcrumb.Item key={key} href={breadCrumbItem.url}>
          {breadCrumbItem.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )

}


const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <Container>

        <Breadcrumbs location={location} />
        
        <br />
        <article
            itemScope
            itemType="http://schema.org/Article"
        >
          <Row maxW={'100%'} spacing='50px'>
            <Row>
              <h1>{post.frontmatter.title}</h1>
              <p>{post.frontmatter.date}</p>
              <p>
                <small>Tags: {post.frontmatter.tags.map((tag) => tag).join(', ')}</small>
              </p>
            </Row>


            <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
            />
          </Row>
        </article>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`
